module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TGnowledgy - Tilmann Glaser","short_name":"TGnowledgy","start_url":"/","background_color":"#000059","theme_color":"#000059","display":"standalone","icon":"src/assets/img/logo_100x100.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
